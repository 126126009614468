import { Region as BaseRegion } from '@kovihq/regions';

export class Region extends BaseRegion {
  static get cityEnum() {
    return super.listActiveRegions().reduce((acc, region) => {
      acc[region.city] = region.cityName;
      return acc;
    }, {});
  }

  static getWorkCities() {
    return super.listActiveRegions().map(region => ({ text: region.cityName, value: region.city }));
  }

  static getAllowedCityGroups() {
    return super
      .listActiveRegions()
      .filter(region => region.isUnknown() === false)
      .map(region => ({ text: region.cityName, value: region.group }));
  }

  static getWorkCitiesByCountry(country) {
    return super
      .listActiveRegions()
      .filter(region => region.country === country)
      .map(region => region.city);
  }
}
